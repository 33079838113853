import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { motion, useAnimation } from "framer-motion"
import { useTranslation } from "react-i18next"

import Button from "../../styles/Button"
import { useOnScreen } from "../../hooks/"
import ContentWrapper from "../../styles/ContentWrapper"
import Underlining from "../../styles/Underlining"
import Icon from "../../components/icons"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  margin-top: 4rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    .section-title {
      margin-bottom: 2rem;
    }
    .content-wrapper {
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .text-content {
      width: 100%;
      max-width: 31.25rem;
      font-size: 1.125rem;

      span.line-through {
        text-decoration: line-through;
      }

    }
    .image-content {
      width: 100%;
      max-width: 18rem;
      margin-left: 0;
      align-self: center;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 2rem;
      }
    }
    .about-author {
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.2s ease-out;
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
      }
    }
  }
`

const StyledWorkContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`

const StyledCompanies = styled.div`
  display: grid;
  /* Calculate how many columns are needed, depending on interests count */
  grid-template-columns: repeat(
    ${({ itemCount }) => itemCount},
    15.625rem
  );
  grid-template-rows: repeat(1, auto);
  grid-auto-flow: column;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 0 2.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Workaround: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout */
  &::after {
    content: "";
    width: ${({ itemCount }) =>
    Math.ceil(itemCount / 2) % 2 === 1 ? "17.125rem" : "2.5rem"};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 15.625rem);
    overflow: visible;
    padding: 0;
  }
  /* Show scrollbar if desktop and wrapper width > viewport width */
  @media (hover: hover) {
    &::-webkit-scrollbar {
      display: block;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 0.2rem solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }
  }
  .company {
    width: 15.625rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 1rem;
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius};
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1rem;
    }
    .logo {
      margin-bottom: 1rem;
      width: 75px;
      height: 40px;
    }
    .company-location {
      display: flex;
      align-items: center;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: +1px;
      font-size: 0.875rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.775rem;
      }
      .icon {
        margin-right: 0.4rem;
        display: flex;
      }
    }
    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem 0;
      .date {
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 0.875rem;
        }
      }
    }
    .specialization-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.875rem;
      }
      .specialization {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 0.5rem;
        line-height: 1.2rem;
        span {
          margin: 0 0.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
`

const StyledEducationContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`

const StyledEducation = styled.div`
  display: grid;
  /* Calculate how many columns are needed, depending on education count */
  grid-template-columns: repeat(
    ${({ itemCount }) => itemCount},
    15.625rem
  );
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 0 2.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Workaround: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout */
  &::after {
    content: "";
    width: ${({ itemCount }) =>
    Math.ceil(itemCount / 2) % 2 === 1 ? "17.125rem" : "2.5rem"};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 15.625rem);
    overflow: visible;
    padding: 0;
  }
  /* Show scrollbar if desktop and wrapper width > viewport width */
  @media (hover: hover) {
    &::-webkit-scrollbar {
      display: block;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 0.2rem solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }
  }

  .education {
    width: 15.625rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius};
    font-size: 1rem;
    p {
      margin: 0;
    }
    .IES {
      font-weight: bold;
    }
    .date {
      font-size: 0.9rem;
    }
  }
`

const About = ({ content }) => {
  const sectionDetails = content[0].node
  const { exports, frontmatter } = content[0].node
  const { t } = useTranslation()

  const Content = t('aboutContent');

  const { companies, education } = exports

  const ref = useRef()
  const onScreen = useOnScreen(ref)
  const refEdu = useRef()
  const onScreenEdu = useOnScreen(refEdu)

  const iControls = useAnimation()
  const bControls = useAnimation()
  const eControls = useAnimation()
  const dControls = useAnimation()

  const shownItems = 5
  const [shownEducation, setshownEducation] = useState(shownItems)

  useEffect(() => {
    const sequence = async () => {
      if (onScreenEdu) {
        // i receives the value of the custom prop - can be used to stagger
        // the animation of each "interest" element
        await eControls.start(i => ({
          opacity: 1, scaleY: 1, transition: { delay: i * 0.1 }
        }))
        await dControls.start({ opacity: 1, scaleY: 1 })
      }
    }
    sequence()
  }, [onScreenEdu, shownEducation, eControls, dControls])

  const showMoreItems = () => setshownEducation(shownEducation + 4)

  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)
  const tVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)
  const iVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  }

  useEffect(() => {
    const sequence = async () => {
      if (onScreen) {
        // i receives the value of the custom prop - can be used to stagger
        // the animation of each "interest" element
        await iControls.start(i => ({
          opacity: 1, scaleY: 1, transition: { delay: i * 0.1 }
        }))
        await bControls.start({ opacity: 1, scaleY: 1 })
      }
    }
    sequence()
  }, [onScreen, iControls, bControls])

  return (
    <StyledSection id="about">
      <StyledContentWrapper>

        <h2 className="section-title">{t(sectionDetails.frontmatter.title)}</h2>

        <div className="content-wrapper">
          <motion.div
            className="inner-wrapper"
            ref={tRef}
            variants={tVariants}
            animate={tOnScreen ? "visible" : "hidden"}
          >
            <div className="text-content">
              <Content t={ t } />
            </div>
          </motion.div>
          <motion.div
            className="image-content"
            ref={iRef}
            variants={iVariants}
            animate={iOnScreen ? "visible" : "hidden"}
          >
            <Img
              className="about-author"
              fluid={frontmatter.image.childImageSharp.fluid}
            />
          </motion.div>

        </div>
      </StyledContentWrapper>

      <StyledWorkContentWrapper>
        <h3 className="section-title">{t('about.work')}</h3>
        <StyledCompanies itemCount={companies.length} ref={ref}>
          {companies.slice(0, 4).map(({ name, date, location, position, specialization, logo }, key) => (
            <motion.div
              className="company"
              key={key}
              custom={key}
              initial={{ opacity: 0, scaleY: 0 }}
              animate={iControls}
            >
              <Img className="logo" fluid={logo.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <span className="company-name">{name}</span>
              <div className="company-location">
                <span className="icon">
                  <Icon name="location" color="#888888" />
                </span>
                <span>{t(location)}</span>
              </div>

              <div className="position">
                <strong>{t(position)}</strong>
                <span className="date">{t(date)}</span>
              </div>

              <div className="specialization-wrapper">
                <span>{t('about.specialized')}</span>
                <div className="specialization">
                  {specialization.map((hability) => (
                    <Underlining
                      key={hability}
                      color="secondary"
                      hoverColor="secondary"
                    >
                      {hability}
                    </Underlining>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </StyledCompanies>
      </StyledWorkContentWrapper>

      <StyledEducationContentWrapper>
        <h3 className="section-title">{t('about.educationLabel')}</h3>
        <StyledEducation itemCount={education.length} ref={refEdu}>
          {education.slice(0, shownEducation).map(({ name, date, course }, key) => (
            <motion.div
              className="education"
              key={key}
              custom={key}
              initial={{ opacity: 0, scaleY: 0 }}
              animate={eControls}
            >
              <p className="IES">{t(name)} <span className="date">({date})</span></p>
              <p>{t(course)}</p>
            </motion.div>
          ))}
          {shownEducation < education.length && (
            <motion.div initial={{ opacity: 0, scaleY: 0 }} animate={dControls}>
              <Button
                onClick={() => showMoreItems()}
                type="button"
                textAlign="left"
                color="primary"
              >
                {t('general.loadmore')}
              </Button>
            </motion.div>
          )}
        </StyledEducation>
      </StyledEducationContentWrapper>

    </StyledSection>
  )
}

About.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          companies: PropTypes.array.isRequired,
          education: PropTypes.array.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default About

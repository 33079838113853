import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { useTranslation } from "react-i18next"

import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import About from "../components/sections/about"
import Skills from "../components/sections/skills"
import Projects from "../components/sections/projects"
import Contact from "../components/sections/contact"

import './styles.css'

const IndexPage = ({ data }) => {

  const { i18n } = useTranslation()

  return (
    <>
      <SEO lang={i18n.language} title="Lucas Gomes @LGOMESP" />
      <Hero content={data.hero.edges} />
      <About content={data.about.edges} />
      <Skills content={data.skills.edges} />
      <Projects content={data.projects.edges} />
      <Contact content={data.contact.edges} />
    </>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
{
  hero: allMdx(filter: {fileAbsolutePath: {regex: "/hero/"}}) {
    edges {
      node {
        body
        frontmatter {
          greetings
          subtitlePrefix
          subtitle
          icon {
            childImageSharp {
              fluid(maxWidth: 60, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  about: allMdx(filter: {fileAbsolutePath: {regex: "/about/"}}) {
    edges {
      node {
        body
        exports {
          companies {
            name
            date
            position
            location
            specialization
            logo {
              childImageSharp {
                fluid(maxWidth: 95, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          education {
            name
            date
            course
          }
        }
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  skills: allMdx(filter: {fileAbsolutePath: {regex: "/skills/"}}) {
    edges {
      node {
        exports {
          shownItems
          skills {
            name
            icon {
              childImageSharp {
                fixed(width: 20, height: 20, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        frontmatter {
          title
        }
      }
    }
  }
  projects: allMdx(filter: {fileAbsolutePath: {regex: "/projects/"}, frontmatter: {visible: {eq: "true"}}}, sort: {fields: [frontmatter___position], order: ASC}) {
    edges {
      node {
        body
        frontmatter {
          title
          category
          body
          emoji
          external
          github
          screenshot {
            childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tags
          position
          buttonVisible
          buttonUrl
          buttonText
        }
      }
    }
  }
  contact: allMdx(filter: {fileAbsolutePath: {regex: "/contact/"}}) {
    edges {
      node {
        body
        frontmatter {
          title
          subtitle
          name
          email
          icon {
            childImageSharp {
              fluid(maxWidth: 60, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`
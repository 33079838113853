import React from "react"
import PropTypes from "prop-types"

const Location = ({ color }) => {
  return (
    <svg height="16px" version="1.1" viewBox="0 0 16 16" width="16px" xmlns="http://www.w3.org/2000/svg"><title /><defs /><g fill="none" fillRule="evenodd" id="Icons with numbers" stroke="none" strokeWidth="1"><g fill={color} id="Group" transform="translate(-288.000000, -192.000000)"><path d="M291.020383,198 C291.006892,197.835307 291,197.668539 291,197.5 C291,194.462434 293.238576,192 296,192 C298.761424,192 301,194.462434 301,197.5 C301,197.668539 300.993108,197.835307 300.979617,198 L301,198 C301,201.005737 296,208 296,208 C296,208 291,201.022339 291,198 Z M296,199 C297.10457,199 298,198.10457 298,197 C298,195.89543 297.10457,195 296,195 C294.89543,195 294,195.89543 294,197 C294,198.10457 294.89543,199 296,199 Z M296,199" id="Oval 208" /></g></g></svg>
  )
}

Location.propTypes = {
  color: PropTypes.string,
}

Location.defaultProps = {
  color: "#000000",
}

export default Location
